@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}



body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  color: #334564DB;
  
}
::-webkit-scrollbar {
  display: none;
}
.poppins{
  font-family: 'Poppins';

}

input[type="color" i] {
  -webkit-appearance: none;
  appearance: none;
  
}
input[type="color" i]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

.pager{
  color: #000;
}
.page{
  color: #334564DB;
}
.turn-page-animation-left {
 
  animation-name: turnPage;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes turnPage {
  0% {
    
    transform: rotateY(0deg);
  }
  100% {
   
    transform: rotateY(-180deg);
  }
}
.turn-page-animation-right {
  
  animation-name: turnPageRight;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes turnPageRight {
  0% {
    
    transform: rotateY(0deg);
  }
  100% {
   
    transform: rotateY(-180deg);
  }
}
.Layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.PageContainer {
  position: relative;
  z-index: 1;
}

.Pages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}